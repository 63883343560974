import './style.css';

const Button = (props) => {
    const {
        label, 
        isVisible = true, 
        children, 
        disabled = false, 
        onClick, 
        className = '', 
        leadingIcon, 
        trailingIcon,
        appearance = 'solid-blue'
    } = props;

    const text = label ?? children;

    if(!isVisible) return null;

    return (
        <button 
            className={`button-style ${className} ${appearance}`} 
            disabled={disabled} 
            onClick={onClick}>
            {leadingIcon}
            {text}
            {trailingIcon}
        </button>
    )
}

export default Button;