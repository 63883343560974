import { RxCross2 } from "react-icons/rx";
import IconButton from "../IconButton";
import "./style.css"


const PopUp = (props) => {
    const { children, onClose, title = null } = props;

    return <div className="popup-container">
        <div className="popup-header">
            {title !== null && typeof(title) === 'function' ? 
                <>
                    {title}
                </> 
                : 
                <p className="title">
                    <img src={'/images/ask_ai.png'} alt="ask-ai"/>
                    {title ?? `Ask AI` }
                </p>
            } 
            <IconButton onClick={onClose}>
                <RxCross2 />
            </IconButton>
        </div>
       
        <div className="content">
            {children}
        </div>
    </div>
}

export default PopUp;