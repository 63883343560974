import { log, sanitizedString } from "../utils/utils";

let debounceTimer;

export function validateSQLQuery(session, dialect, handleEditorChange, allAutocomplete, restConnectors, globalTokenMap) {
    if (debounceTimer) {
        clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
        let annotations = [];
        const lintingAPIURL = {
            REACT_APP_SQL_LINT: process.env.REACT_APP_SQL_LINT
        }

        fetch(lintingAPIURL.REACT_APP_SQL_LINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('accessToken'),
                'Nected-Ws': window.localStorage.getItem('workspaceUUID')
            },
            body: JSON.stringify({ "sql_code": sanitizedString(session.getValue(), allAutocomplete, globalTokenMap), "dialect": dialect !== "pgsql" ? dialect : 'postgres' })
        })
            .then(res => res.json())
            .then(res => {
                res.linting_result.forEach((i) => {
                    annotations.push({
                        row: i.line_no - 1,
                        column: i.line_pos,
                        text: i.description,
                        type: "error"
                    });
                });
                // session.setAnnotations(annotations);
                handleEditorChange(dialect, annotations, restConnectors);
            })
            .catch(error => log("Error ===>", error));
    }, 500);
}
