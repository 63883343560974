import './style.css';

const Input = (props) => {
    const {label, type = 'text', className } = props;

    return <div className={`input-container ${className}`}>
        <div className="label">{label}</div>

        {type === 'textarea' ? (
            <textarea 
                {...props}
                className='textarea-field' 
            /> )
        : (
            <input 
                {...props}
                className='input-field'
            />
        )}
        
    </div>
}

export default Input;