/* eslint-disable */
ace.define("ace/theme/mongo",["require","exports","module","ace/lib/dom"], function(acequire, exports, module) {

    exports.isDark = false;
    exports.cssClass = "ace-mongo";
    exports.cssText = "\
    .ace-mongo .ace_gutter {\
    background: #ebebeb;\
    color: #333;\
    overflow: hidden;\
    }\
    .ace-mongo  {\
    background: #f5f6f7;\
    color: #000;\
    }\
    .ace-mongo .ace_keyword {\
    color: #999999;\
    font-weight: normal;\
    }\
    .ace-mongo .ace_key {\
    color: #a12b00;\
    font-weight: normal;\
    }\
    .ace-mongo .ace_gutter-cell {\
    padding-left: 19px;\
    padding-right: 13px;\
    }\
    .ace-mongo .ace_string {\
    color: #5b81a9;\
    }\
    .ace-mongo .ace_boolean {\
    color: #5b81a9;\
    font-weight: normal;\
    }\
    .ace-mongo .ace_constant.ace_numeric {\
    color: #5b81a9;\
    }\
    .ace-mongo .ace_string.ace_regexp {\
    color: #5b81a9;\
    }\
    .ace-mongo .ace_variable.ace_class {\
    color: teal;\
    }\
    .ace-mongo .ace_constant.ace_buildin {\
    color: blue;\
    }\
    .ace-mongo .ace_support.ace_function {\
    color: blue;\
    }\
    .ace-mongo .ace_function {\
    color: blue;\
    }\
    .ace-mongo .ace_comment {\
    color: #998;\
    font-style: italic;\
    }\
    .ace-mongo .ace_variable.ace_language  {\
    color: blue;\
    }\
    .ace-mongo .ace_paren {\
    font-weight: normal;\
    }\
    .ace-mongo .ace_variable.ace_instance {\
    color: teal;\
    }\
    .ace-mongo .ace_constant.ace_language {\
    font-weight: bold;\
    }\
    .ace-mongo .ace_cursor {\
    color: #999999;\
    }\
    .ace-mongo.ace_focus .ace_marker-layer .ace_active-line {\
    background: rgba(0, 0, 0, 0.07);\
    }\
    .ace-mongo .ace_marker-layer .ace_active-line {\
        background: rgba(0, 0, 0, 0.07);\
    }\
    .ace-mongo .ace_marker-layer .ace_selection {\
    background: rgb(181, 213, 255);\
    }\
    .ace-mongo.ace_multiselect .ace_selection.ace_start {\
    box-shadow: 0 0 3px 0px white;\
    }\
    .ace-mongo.ace_nobold .ace_line > span {\
    font-weight: normal !important;\
    }\
    .ace-mongo .ace_marker-layer .ace_step {\
    background: rgb(252, 255, 0);\
    }\
    .ace-mongo .ace_marker-layer .ace_stack {\
    background: rgb(164, 229, 101);\
    }\
    .ace-mongo .ace_marker-layer .ace_bracket {\
    margin: -1px 0 0 -1px;\
    border: 1px solid rgb(192, 192, 192);\
    }\
    .ace-mongo .ace_gutter-active-line {\
    background: #dcdcdc;\
    }\
    .ace-mongo .ace_marker-layer .ace_selected-word {\
    background: rgb(250, 250, 255);\
    border: 1px solid rgb(200, 200, 250);\
    }\
    .ace-mongo .ace_invisible {\
    color: #BFBFBF\
    }\
    .ace-mongo .ace_print-margin {\
    width: 1px;\
    background: #e8e8e8;\
    }\
    .ace-mongo .ace_hidden-cursors {\
      opacity: 0;\
    }\
    .ace-mongo .ace_indent-guide {\
    background: url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==\") right repeat-y;\
    }";
      var dom = acequire("../lib/dom");
      dom.importCssString(exports.cssText, exports.cssClass);
    });