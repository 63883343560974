import { useState } from 'react';
import { BiSend } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import Button from '../Button';
import Input from '../Input';
import './style.css'
import {  querySuffix } from './constant';
import Spinner from '../Spinner';
import { getSystemBehaviourForAi } from '../../utils/utils';
import { toasts } from '../Toaster/toasts';

const AskAISuggestion = (props) => {
    const {type, handleInsertSuggestion } = props;

    const [isEditable, setIsEditable] = useState(true);
    const [queryIndex, setQueryIndex] = useState(0);
    const [query, setQuery] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const updatedQuery = [...query];
        updatedQuery[queryIndex] = {
            question: value,
            answer: ''
        }
        
        setQuery(updatedQuery);
    }

    const generatePromptForAi = () => {
        if(query.length > 1) {
            return (query[queryIndex-1].answer ?? '') 
                + '\n' 
                + (query[queryIndex].question ?? '') 
                + querySuffix
        } else if(query.length > 0) {
            return (query[queryIndex].question ?? '') 
                + querySuffix
        } 

        return querySuffix;
    }

    const makeSuggestionRequest = async () => {
        try {
            setIsLoading(true);
            const prompt = generatePromptForAi();

            const accessToken = window.localStorage.getItem("accessToken");
            const workspaceUUID = window.localStorage.getItem("workspaceUUID");

            const headers = {
                Authorization: accessToken,
                "Nected-WS": workspaceUUID,
                'Content-Type': 'application/json',
            };

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    system_behaviour: getSystemBehaviourForAi(type),
                    prompt
                }),
            }

            const response = await fetch(process.env.REACT_APP_AI_SUGGESTION,  requestOptions)
            .then(res => res.json());

            const parsedResponse = JSON.parse(response.result);
            const result = parsedResponse?.code ?? parsedResponse?.error ?? parsedResponse;

            const updatedQuery = [...query];

            if(updatedQuery.length > 0) {
                updatedQuery[queryIndex].answer = result;
                setQuery(updatedQuery);
            }

            setIsEditable(false);
            setIsLoading(false);
        } catch(e) {
            toasts.error("It seems there is some error, please try again",'ai-generate-msg-error')
        }

        setIsLoading(false);
         
    }

    const handleDiscardClick = () => {
        const updatedQuery = [...query];

        updatedQuery.pop();
        setQuery(updatedQuery);

        if(queryIndex > 0) {
            setQueryIndex(queryIndex-1);
        }
        
        setIsEditable(false);
    }

    const handleEditSuggestion =  () => {
        const updatedQuery = [...query];

        if(updatedQuery.length > 0) {
            updatedQuery.push({
                question: updatedQuery[queryIndex].question,
                answer: ''
            })

            setQuery(updatedQuery)
            setQueryIndex(queryIndex+1);
        }

        setIsEditable(true)    
    }

    const handleAcceptClick = () => {
        const valueToinsert = query[queryIndex].answer;
        handleInsertSuggestion(valueToinsert);        
    }

    const handleSubmitClick = () => {
        makeSuggestionRequest();
    }

    return <div onClick={e => {e.stopPropagation()}} className='ai-suggestion-container'>
        <div>
            <Input 
                type='textarea' 
                rows={6} 
                placeholder='Write your Query' 
                disabled={!isEditable}
                onChange={handleInputChange} 
                value={query.length > 0 ? query[queryIndex].question : ''}
            />  
        </div>
        <div className='button-container' >
            <Button 
                isVisible={isEditable && queryIndex > 0} 
                appearance="solid-whiteSmoke"
                onClick={handleDiscardClick} 
                leadingIcon={<RxCross2 />}> 
                    Cancel
            </Button>

            <Button 
                isVisible={!isEditable} 
                appearance="solid-whiteSmoke"
                leadingIcon={<MdOutlineEdit />}
                onClick={handleEditSuggestion}>
                    Edit
            </Button>

            <Button 
                appearance='solid-whiteSmoke'
                onClick={handleSubmitClick}
                isVisible={isEditable}
                disabled={query.length > 0 ? query[queryIndex].question === '' : true}
                leadingIcon={isLoading ? null : <BiSend />}
            >
                {isLoading ? <Spinner size='extra-small' /> : "Send"}
            </Button>
        </div>

        {query.length > 0 
            && query[queryIndex].answer !== '' 
            && <>
                <div className='answer-container'>
                    <Input 
                        type='textarea' 
                        rows={6} 
                        placeholder='your answer' 
                        disabled={true}
                        value={query.length > 0 ? query[queryIndex].answer : ''}
                    />
                </div>

                <div className='button-container'>
                    <Button 
                        isVisible={!isEditable} 
                        appearance='solid-green' 
                        leadingIcon={<IoMdCheckmark />}
                        onClick={handleAcceptClick}>
                            Insert
                    </Button>
                </div>
            </>}

    </div>
}

export default AskAISuggestion;