import { dateComputeSuggestions } from "../utils/utils";

const { useState, useEffect } = require("react");

const TYPE_MAP = {
    "select_list_token" : ["list", "array"],
    "select_date_token" : ["date" , "dateTime"],
    "select_unit" : ["unit"]
} 

export const SelectList = ({ styles, allAutocomplete, callback , openSelectTokenList }) => {
    const [clickedIndex, setClickedIndex] = useState(null);
    const [tokenType , setTokentype] = useState([]);
    const [selectedToken, setSelectedToken] = useState("");
    const [,setSelectedKey] = useState("");
    const [tokenList, setTokenList] = useState([]);
    const [keyList, setKeyList] = useState([]);

    useEffect(() => {
        const contextMenu = document.getElementById("token-menu");
        const tokenType = TYPE_MAP[contextMenu.getAttribute("data-type")];
        if(tokenType && tokenType.length > 0){
            setTokentype(tokenType);
            setTokenList(allAutocomplete.filter((i) => tokenType.includes(i.meta)))
        }
        if(tokenType && contextMenu.getAttribute("data-type") === "select_unit"){
            setTokentype(tokenType);
            setTokenList(dateComputeSuggestions)
        }
    }, [allAutocomplete , openSelectTokenList]);

    const handleMenuClick = (index) => {
        if (index === clickedIndex) {
            setClickedIndex(null)
        }
        else {
            setClickedIndex(index)
        }
    }

    function extractKeysAndTypesWithPaths(obj) {
        const uniqueKeys = []; // Using an array to maintain insertion order

        function explore(obj, path) {
            for (const key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                const value = obj[key];
                const type = typeof value;

                // Construct the key name based on the current path
                const currentPath = path ? `${path}.${key}` : key;

                // If the property is an object (but not null, and not an array), explore it recursively
                if (type === 'object' && value !== null && !Array.isArray(value)) {
                    explore(value, currentPath);
                } else {
                    // Check if the currentPath is already added, to handle cases where the same path could potentially be added twice
                    if (!uniqueKeys.some(item => item.name === currentPath)) {
                        uniqueKeys.push({ name: currentPath, type: type === 'object' && value === null ? 'null' : type });
                    }
                }
            }
        }

        explore(obj, '');

        return uniqueKeys;
    }

    useEffect(() => {
        if (clickedIndex !== null) {
            const token = tokenList[clickedIndex];
            setSelectedToken(token);
            if (token.executedValue && token.executedValue.length > 0) {
                // check if first object is object if yes then give selected key.
                if (typeof token.executedValue[0] === "object") {
                    if(extractKeysAndTypesWithPaths(token.executedValue[0]).length > 0){
                        setKeyList(extractKeysAndTypesWithPaths(token.executedValue[0]));
                    }
                    else{
                        setClickedIndex(null);
                        callback({ token , tokenType})
                    }
                }
                else {
                    setClickedIndex(null);
                    callback({ token , tokenType})

                }
            }
            else {
                setClickedIndex(null);
                callback({ token , tokenType})

            }
        }
    }, [callback, clickedIndex, tokenList, tokenType]);


    const handleSelectKey = (index) => {
        setSelectedKey(keyList[index].name);
        callback({ token: selectedToken, selectedKey: keyList[index].name , tokenType })
        setClickedIndex(null);
    }

    return (
        <>
            <div style={{ ...styles }} id="token-menu">
                {clickedIndex === null &&
                    <>
                        <div className="menu-title">Select {`${tokenType.join("/")}`} token</div>
                        <div className="list-wrapper">
                            {tokenList.map(function (i, index) {
                                return (
                                    <div key={index} className="parentItem" data-cvalue={i.value} onClick={() => handleMenuClick(index)}>
                                        <div className="name">{i.name}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        {tokenList.length === 0 &&
                            <div className="parentItem" >
                                <div className="icon"></div>
                                <div className="name">No {`${tokenType.join("/")}`} tokens</div>
                                <div className="details"></div>
                            </div>
                        }
                    </>
                }
                {(clickedIndex !==  null && keyList.length > 0) &&
                    <>
                        <div className="menu-title">Select Key</div>
                        <div className="list-wrapper">
                            {keyList.map(function (i, index) {
                                return (
                                    <div key={index} className="parentItem" data-cvalue={i.value} onClick={() => handleSelectKey(index)}>
                                        <div className="name">{i.name}  <span className="key-datatype">({i.type})</span></div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className="menu-back" onClick={() => setClickedIndex(null)}>Go Back</div>
                    </>
                }
            </div >
        </>
    )
}