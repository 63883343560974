import { sanitizedString } from '../utils/utils';

export function validateListString(session, mode, handleEditorChange, allAutocomplete, restConnectors, globalTokenMap) {
    const annotations = [];
    const sanitizedValue = sanitizedString(session.getValue(), allAutocomplete, globalTokenMap)
    try{
        JSON.parse(sanitizedValue);
    }
    catch(error){
        annotations.push({
            row: 0,
            column: 0,
            text: error,
            type: "error"
        });
    }
    session.setAnnotations(annotations);
    handleEditorChange(mode, annotations, restConnectors);
}
