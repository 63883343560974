import { toast } from 'react-toastify';

export const toasts= {
  info: (message, toastId) =>
    toast.info(message, {
      toastId,
    }),
  infoBlue: (message, toastId) =>
    toast.info(message, {
      toastId,
    }),
  success: (message, toastId) =>
    toast.success(message, {
      toastId,
    }),
  error: (message, toastId) =>
   {    
     toast.error(message, {
      toastId,
    })
   },
  warning: (message, toastId) =>
    toast.warning(message, {
      toastId,
    }),
};
