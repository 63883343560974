/* eslint-disable */

import { useState, useEffect } from "react";
import Button from "../Button";
import './style.css';
import Input from "../Input";
import IconButton from "../IconButton";
import { BiSend } from "react-icons/bi";
import { LuRefreshCcw } from "react-icons/lu";
import Spinner from "../Spinner";
import { extractMacros, extractTokens, functionSignatures, getSystemBehaviourForAi, sanitizedString } from "../../utils/utils";
import { toasts } from "../Toaster/toasts";

export const ExplainCodeAI = (props) => {
    const { type, tokens, allAutocomplete,  code, setSuggestionType } = props;

    const [query, setQuery]=  useState();
    const [queryResult, setQueryResult] = useState();
    const [isEditable, setIsEditable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        void makeSuggestionRequest();
    },[])

    useEffect(() => {
        if(code === undefined || code === null || code ==='') {
            setSuggestionType('');
        }
    },[code])

    const getMACROSDoc = (usedMACROS) => {
        let documentString = '';
    
        usedMACROS.forEach((macro) => {
            documentString = documentString + '\n' + JSON.stringify(functionSignatures[`${macro}`]);
        });
    
        return documentString;
    }

    const generatePromptForAi = (isFollowUp = false) => {
         const tokensText = `/** const tokens = ${JSON.stringify(tokens)} **/
            I have provided the tokens used in code. Token is an object
            of key/value pair where key is used inside the code and the
            value object contains following properties: "executableValue" and "meta".
            the key is replaced by the value provided by its "executableValue" key and 
            its type is defined by "meta" key. Also if needed, consider these
            tokens are present in window scope and can be used in the code`
        
        const macros = `/** \n${getMACROSDoc(extractMacros(code))}**/
            The above provided object has information about the MACRO functions 
            used in code as follow:
            Each key is representing the function name and its value has following
            properties: "signature" (it tells about the function signature) and 
            "description" (it tells about the function implementation, its 
            input/output). If these keys are used in the code 
            then consider them as custom functions. Also provide the code explanation based
            on given information present inside "signature" and "description" keys.
            These custom built-in functions are provided in the Ecosystem.We call these built-in functions as MACROS.
            You can add saying if need to learn more about these MACROS you can refer to Documentation.
            `

        

        if(isFollowUp) {
            return `previous query result -> ${queryResult} 
                current asked question -> ${query}. 
                Return object with {"explain"  : "" , error : ""} keys.
                explain and error are of string type. dont send stringified object as value in both the keys.
                Provide the explanation inside the "explain" key. If you unable 
                to provide the answer then provide the result inside "error" key.`
        }

        return `${sanitizedString(code, allAutocomplete, tokens)} 
            ${extractMacros(code).length> 0 ?  macros : ''}
            ${extractTokens(code).length> 0 ?  tokensText : ''}
            Return object with {"explain"  : "" , error : ""} keys.
            explain and error are of string type. dont send stringified object as value in both the keys.
            Provide the above code explanation inside "explain" key. 
            If you unable to provide the answer then provide the result 
            inside "error" key.`
    }

    const makeSuggestionRequest = async (isFollowUp = false) => {
        try {
            setIsEditable(false);
            setIsLoading(true);

            const accessToken = window.localStorage.getItem("accessToken");
            const workspaceUUID = window.localStorage.getItem("workspaceUUID");

            const headers = {
                Authorization: accessToken,
                "Nected-WS": workspaceUUID,
                'Content-Type': 'application/json',
            };

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    system_behaviour: getSystemBehaviourForAi(type),
                    prompt: generatePromptForAi(isFollowUp)
                }),
            }

            const response = await fetch(process.env.REACT_APP_AI_SUGGESTION,  requestOptions)
            .then(res => res.json());

            const parsedResponse = JSON.parse(response.result);

            const result = parsedResponse?.explain ?? parsedResponse?.error ?? parsedResponse;
            setQueryResult(result);
        } catch(e) {
            toasts.error("It seems there is some error, please try again",'ai-generate-msg-error')

        }
        setIsEditable(true);
        setIsLoading(false);
    }

    const handleInputChange = (e) => {
        setQuery(e.target.value)
    }

    const handleSubmitClick = () => {
        void makeSuggestionRequest(true)
    }

    const handleRefresh = () => {
        void makeSuggestionRequest();
    }

    return <div className='explain-code-ai-container' 
                onClick={e => {e.stopPropagation()}} >

        <IconButton onClick={handleRefresh} className='refresh-explain-code-ai' >
            <LuRefreshCcw />
        </IconButton>
         <div>
            <Input
                type='textarea' 
                rows={6} 
                placeholder='Your answer' 
                disabled={true}
                value={queryResult}
            />
        </div>
        <div className="ask-question-container">
            <Input 
                type='text' 
                placeholder='Ask follow up question' 
                disabled={!isEditable}
                onChange={handleInputChange} 
                value={query}
                className="explain-code-input"
            />  
            <Button
                appearance='solid-whiteSmoke'
                className='send-btn'
                onClick={handleSubmitClick}
                disabled={isLoading}
                leadingIcon={isLoading ? null : <BiSend size={16} />}
            >
                {isLoading ? <Spinner size='extra-small' /> : ""}
            </Button>
        </div>
    </div>
}