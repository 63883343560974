import { sanitizedString } from "../utils/utils"

const checkMongoQueryKeys = (queryString) => {
  const lines = queryString.split('\n');
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const keyMatches = line.match(/(["']?)([^"'\s]+)\1\s*:/g); // Corrected regex

    if (keyMatches) {
      for (const keyMatch of keyMatches) {
        const [, quote, key] = keyMatch.match(/(["']?)([^"'\s]+)\1\s*:/); // Corrected regex
        if (!quote && !key.startsWith('"') && !key.startsWith("http")) {
          if (!key.includes(":")) {
            return { isValid: false, lineNumber: i + 1, keyName: key };
          }
        }
      }
    }
  }
  return { isValid: true };
}


export function validateMongoQuery(parser, session, type, handleEditorChange, allAutocomplete, restConnectors, globalTokenMap) {
  let annotations = []
  const sanitizedValue = sanitizedString(session.getValue(), allAutocomplete, globalTokenMap)
  const err = parser.parse(sanitizedValue, type, 'bson')
  const { isValid, lineNumber, keyName } = checkMongoQueryKeys(sanitizedValue);
  if (err != null) {
    const pos = session.getDocument().indexToPosition(err.at - 1)
    annotations.push({
      row: pos.row,
      column: pos.column,
      text: err.message,
      type: "error"
    })
  }
  if (!isValid) {
    annotations.push({
      row: lineNumber - 1,
      column: 0,
      text: `Quotes are missing in key "${keyName}"`,
      type: "error"
    })
  }
  handleEditorChange("mongodb", annotations, restConnectors)
}