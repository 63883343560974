import { sanitizedString } from '../utils/utils';
const durableJsonLint = require('durable-json-lint');

export function validateJSON(session, mode, handleEditorChange, allAutocomplete, restConnectors, globalTokenMap) {
    const sanitizedValue = sanitizedString(session.getValue(), allAutocomplete, globalTokenMap)
    const error = durableJsonLint(sanitizedValue)
    const annotations = [];
    error.errors.forEach((i) => {
        annotations.push({
            row: i.lineNumber - 1,
            column: i.column,
            text: i.description || i.message,
            type: "error"
        });
    });
    // session.setAnnotations(annotations);
    handleEditorChange(mode, annotations, restConnectors);
}
