import { ToastContainer } from 'react-toastify';

export function Toaster() {
  return (
   <ToastContainer
        id="nectedEditorToastContainer"  
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeButton={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={5}        
        />
  );
}
