import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const JSONAccordion = ({ title, children, type , defaultState , helpLinesNeeded=true , id}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() =>{
    // setIsOpen(defaultState)
  },[defaultState , id])

  return (
    <>
      {type === "value-obj" ? (
        <>
          <div className="accordion-header" >
            <div className="accordion-icon" onClick={(e) => toggleAccordion(e)}>
              {isOpen ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowRight />}
            </div>
            {typeof title === "string" ? <span>{title}</span> : title}
          </div>
          {isOpen && <div className="accordion-content">
            {helpLinesNeeded &&
              <>
                <div className="accordian-horizontal"></div>
                <div className="accordian-vertical"></div>
              </>
            }
            {children}
        </div>}
        </>
      ) : <>
        {title}
        {children}
      </>}
    </>
  );
};

export default JSONAccordion;
