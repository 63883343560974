import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import NectedEditor from "./editor";
import { Toaster } from "./components/Toaster";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const SENTRYDSN = {
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};
const REACTAPPENV = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
};
const DEPLOYMENT_REGION = {
  REACT_APP_DEPLOYMENT_REGION: process.env.REACT_APP_DEPLOYMENT_REGION,
};

if (REACTAPPENV.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: SENTRYDSN.REACT_APP_SENTRY_DSN,
    environment:
      DEPLOYMENT_REGION.REACT_APP_DEPLOYMENT_REGION === "" ||
      DEPLOYMENT_REGION.REACT_APP_DEPLOYMENT_REGION === undefined
        ? REACTAPPENV.REACT_APP_ENV
        : `${REACTAPPENV.REACT_APP_ENV}-${DEPLOYMENT_REGION.REACT_APP_DEPLOYMENT_REGION}`,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <NectedEditor />
      <Toaster />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
