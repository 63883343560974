import './style.css';

const IconButton = (props) => {
    const {
        children, 
        disabled = false, 
        onClick, 
        className = '', 
        isVisible = true,
    } = props;


    if(!isVisible) return null;

    return (
        <button 
            className={`icon-button-style ${className}`} 
            disabled={disabled} 
            onClick={onClick}>
           
            {children}
        </button>
    )
}

export default IconButton;