import { sanitizedString } from '../utils/utils';


export function validateJS(session, mode, handleEditorChange, allAutocomplete , restConnectors, globalTokenMap) {
    const Linter = require("eslint4b-prebuilt/dist/eslint4b");
    const linter = new Linter();
    const sanitizedValue = sanitizedString(session.getValue(), allAutocomplete, globalTokenMap)
    let errorMessages = linter.verify(sanitizedValue, {
        parserOptions: {
            ecmaVersion: 9,
        },
    });
    const annotations = [];
    errorMessages.forEach((i) => {
        annotations.push({
            row: i.line - 1,
            column: i.column,
            text: i.message,
            type: "error"
        });
    });
    handleEditorChange(mode, annotations, restConnectors);
}
