import React, { useState, useEffect, useRef } from 'react';

export const AutoSuggestions = ({ suggestions, value, onChange, placeholder }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputValue === '') {
      setFilteredSuggestions(suggestions);
    } else {
      const lastWord = inputValue.split(' ').pop();
      const filtered = suggestions.filter((sug) =>
        sug.value.toLowerCase().includes(lastWord.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }
  }, [inputValue, suggestions]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setCursor(-1);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setCursor((prev) => (prev < filteredSuggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      setCursor((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && cursor >= 0) {
      e.preventDefault();
      selectSuggestion(filteredSuggestions[cursor]);
    }
  };

  const selectSuggestion = (suggestion) => {
    const words = inputValue.split(' ');
    const currentWordIndex = getCurrentWordIndex(inputValue, inputRef.current.selectionStart);
    const start = words.slice(0, currentWordIndex).join(' ').length;
    const end = start + words[currentWordIndex].length;
    let spaceAfterCurrentWord = '';
    if (inputRef.current.selectionStart !== inputValue.length) {
      spaceAfterCurrentWord = inputValue[end] === ' ' ? '' : ' ';
    }
    const newValue = inputValue.slice(0, start) + suggestion.value + spaceAfterCurrentWord + inputValue.slice(end);
    setInputValue(newValue);
    setShowSuggestions(false);
    inputRef.current.focus();
    if (onChange) {
      onChange(newValue);
    }
  };
  const handleClickSuggestion = (suggestion) => {
    selectSuggestion(suggestion);
  };

  const handleFocus = () => {
    if (inputValue === '') {
      setFilteredSuggestions(suggestions);
    }
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    setShowSuggestions(false);
  };

  const highlightMatchedText = (text, pattern) => {
    const regex = new RegExp(`(${pattern})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: yellow;">${match}</span>`);
  };

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        ref={inputRef}
        style={{ width: '200px', padding: '8px', boxSizing: 'border-box' }}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list" style={suggestionsListStyle}>
          {filteredSuggestions.map((sug, index) => (
            <li
              key={index}
              className={cursor === index ? 'active' : ''}
              onMouseDown={() => handleClickSuggestion(sug)}
              style={cursor === index ? { ...suggestionItemStyle, ...activeSuggestionStyle } : suggestionItemStyle}
              dangerouslySetInnerHTML={{
                __html: highlightMatchedText(sug.value, inputValue.split(' ').pop()),
              }}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const suggestionsListStyle = {
  border: '1px solid #ccc',
  maxHeight: '150px',
  overflowY: 'auto',
  padding: '0',
  listStyle: 'none',
  margin: '0',
  position: 'absolute',
  backgroundColor: 'white',
  width: '100%',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
};

const suggestionItemStyle = {
  padding: '5px',
  cursor: 'pointer',
  fontSize: '10px',
};

const activeSuggestionStyle = {
  backgroundColor: '#eee',
};

// Function to get the index of the current word based on cursor position
const getCurrentWordIndex = (inputValue, cursorPosition) => {
  const words = inputValue.split(' ');
  let index = 0;
  let count = 0;
  for (let i = 0; i < words.length; i++) {
    count += words[i].length;
    if (count >= cursorPosition) {
      index = i;
      break;
    }
    count++; // Account for the space between words
  }
  return index;
};

export default AutoSuggestions;
