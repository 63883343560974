import { CONTENT_TYPES, EXCLUDE_CONTENT_TYPES, METHODS } from "../utils/utils";

const { useState } = require("react");

export const ContextMenu = ({ connectors, styles, callback, updateSelectedConnector }) => {
    const [clickedIndex, setClickedIndex] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [,setCT] = useState(null);

    const handleMenuClick = (index) => {
        if (index === clickedIndex) {
            setClickedIndex(null)
        }
        else {
            setClickedIndex(index)
        }
    }

    const handleSelectedMethod = (e,method) => {
        e.preventDefault();
        setSelectedMethod(method);
        updateSelectedConnector(connectors[clickedIndex]);
        if(EXCLUDE_CONTENT_TYPES.includes(method)){
            callback({ connectorId: connectors[clickedIndex].value, connectorName: connectors[clickedIndex].name, selectedMethod: method , contentType : "application/json" });
            setClickedIndex(null);
            setSelectedMethod(null);
        }
    }

    const handleContentType = (e,ct) =>{
        e.preventDefault();
        setCT(ct);
        callback({ connectorId: connectors[clickedIndex].value, connectorName: connectors[clickedIndex].name, selectedMethod , contentType : ct });
        setClickedIndex(null);
        setSelectedMethod(null);
        setCT(null);
    }

    return (
        <>
            <div style={{ ...styles }} id="context-menu">
                {clickedIndex === null &&
                    <>
                        <div className="menu-title">Select Connector</div>
                        <div className="list-wrapper">
                        {connectors.map(function (i, index) {
                                return (
                                    <div key={index} className="parentItem" data-cvalue={i.value} onClick={() => handleMenuClick(index)}>
                                        <div className="icon"><img alt="icon" src={`/konark/website/icons/restAPI-removebg-preview.png`} width="20px"/></div>
                                        <div className="name">{i.name}</div>
                                        <div className="details"></div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        {connectors.length === 0 &&
                            <div  className="parentItem" >
                                <div className="icon"><img alt="icon" src={''} width="20px"/></div>
                                <div className="name">No Rest connectors created</div>
                                <div className="details"></div>
                            </div>
                        }
                    </>

                }
                {(clickedIndex !== null && selectedMethod === null) &&
                    <>
                    <div className="menu-title">Select Method</div>
                    <div className="sub-item-menu">
                            {METHODS.map((i, jindex) => {
                                return (
                                    <div className="single-item" data-mvalue={i} key={jindex} onClick={(e) => handleSelectedMethod(e,i)}>{i}</div>
                                )
                            })
                            }
                    </div>
                    <div className="menu-back" onClick={() => setClickedIndex(null)}>Go Back</div>
                    </>
                }
                {(clickedIndex !== null && selectedMethod !== null) &&
                    <>
                    <div className="menu-title">Select Content Type</div>
                    <div className="sub-item-menu">
                            {CONTENT_TYPES.map((i, kindex) => {
                                return (
                                    <div className="single-item" data-mvalue={i} key={kindex} onClick={(e) => handleContentType(e,i)}>{i}</div>
                                )
                            })
                            }
                    </div>
                    <div className="menu-back" onClick={() => setSelectedMethod(null)}>Go Back</div>
                    </>

                }
            </div >
        </>
    )
}